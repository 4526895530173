// extracted by mini-css-extract-plugin
export var carouselcontainer = "ecommerce-module--carouselcontainer--redXT";
export var checkout = "ecommerce-module--checkout--NIN15";
export var dfeatureupper = "ecommerce-module--dfeatureupper--MpRqe";
export var ecombody = "ecommerce-module--ecombody--iMkdi";
export var ecommvp = "ecommerce-module--ecommvp--NTG9k";
export var featureselected = "ecommerce-module--featureselected--RE4+M";
export var featureupper = "ecommerce-module--featureupper--4BMLu";
export var form = "ecommerce-module--form--mAN-0";
export var former = "ecommerce-module--former--6M-D-";
export var formitem = "ecommerce-module--formitem--NYKzR";
export var formupper = "ecommerce-module--formupper--JLhOQ";
export var inner = "ecommerce-module--inner--7-0p3";
export var mfeatures = "ecommerce-module--mfeatures--Ja-2Z";
export var mtotal = "ecommerce-module--mtotal--+EiFu";
export var nothing = "ecommerce-module--nothing--ntE0J";
export var number = "ecommerce-module--number--Foq-T";
export var ordereditem = "ecommerce-module--ordereditem--C9Fl9";
export var ordereditems = "ecommerce-module--ordereditems--v8PFP";
export var orderedupper = "ecommerce-module--orderedupper--T-z9j";
export var submitecom = "ecommerce-module--submitecom--hY5li";
export var total = "ecommerce-module--total--avrzP";
export var warning = "ecommerce-module--warning--UrOZd";